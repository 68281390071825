<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :list-items="listItems"
      :show-brands-filter="true"
      :show-search-filter="true"
      :show-driver-availability-filter="true"
      :brand-list="brandList"
      :region-list="regionList"
      :is-editable="false"
      :csv-data="csvData"
      csv-title="Drivers"
      row-click-path="/tables/drivers"
      endpoint="drivers"
      @getData="getData"
      @create="createDriver()"
      @edit="data => editDriver(data)"
      @delete="id => deleteUser(id)"
      @restore="id => restoreDriver(id)"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              v-if="editDialog.type != 'edit'"
              xs12
              sm6>
              <users-input
                v-model="editDialog.data.user"
                @changeUser="(inputUser) => { editDialog.data.user = inputUser.id }" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList"
                :rules="[v => v.length > 0 || 'Please select at least 1 brand']"
                v-model="editDialog.data.brands"
                :item-text="(val) => { return val.name }"
                clearable
                item-value="id"
                multiple
                label="Brands"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <v-switch
                v-model="editDialog.data.business"
                label="Business"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.companyName"
                label="Company name"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                :disabled="!brandList.length || !editDialog.data.brands || !editDialog.data.brands.length || !editDialog.data.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.DRIVE_ME).id)"
                v-model="driverTypes.driveme"
                :items="typesListBrand(BRAND.DRIVE_ME)"
                :item-text="(val) => { return val.title + ' id: ' + val.id }"
                label="DriveMe Type id"
                item-value="id"
                clearable
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                :disabled="!brandList.length || !editDialog.data.brands || !editDialog.data.brands.length || !editDialog.data.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.RENTABOB).id)"
                v-model="driverTypes.rentabob"
                :items="typesListBrand(BRAND.RENTABOB)"
                :item-text="(val) => { return val.title + ' id: ' + val.id }"
                label="Rent A Bob Type id"
                item-value="id"
                clearable
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                :disabled="!brandList.length || !editDialog.data.brands || !editDialog.data.brands.length || !editDialog.data.brands.map(brand => brand.id || brand).includes(brandList.find(item => item.name == BRAND.EDRIVERS).id)"
                v-model="driverTypes.edrivers"
                :items="typesListBrand(BRAND.EDRIVERS)"
                :item-text="(val) => { return val.title + ' id: ' + val.id }"
                label="E-drivers Type id"
                item-value="id"
                clearable
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.personnelNumber"
                type="text"
                label="Personnel number"
                class="fs-14"
              />
            </v-flex>
            <v-flex
              xs12
              sm4>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.tariffs.driveme"
                  label="DriveMe Tariff"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  type="number"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm4>
              <InputHotKeyWrapper>
                <v-text-field
                v-model="editDialog.data.tariffs.edrivers"
                label="Edrivers Tariff"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  type="number"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm4>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.tariffs.rentabob"
                  label="Rentabob Tariff"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  type="number"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.minHoursPerRide"
                :max-length="TEXT_FIELD_MAX_LENGTH"
                label="Min Hours Per Ride"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.hoursExperience"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  type="number"
                  label="Experience hours"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.yearsExperience"
                  :max-length="TEXT_FIELD_MAX_LENGTH"
                  type="number"
                  label="Experience years"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <address-input
                :address="editDialog.data.address"
                label="Address"
                @onChange="(val) => { editDialog.data.address = val.address}"/>
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.description"
                label="Description"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-textarea
                v-model="editDialog.data.adminNote"
                label="Admin note"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                v-model="editDialog.data.certificates"
                :items="certificatesList"
                :item-text="(val) => { return val.title + ' id: ' + val.id }"
                :item-value="val => val"
                multiple
                label="Certificate"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-autocomplete
                v-model="editDialog.data.regions"
                :items="regionList"
                :item-text="(val) => { return val.name + ' id: ' + val.id }"
                :item-value="val => val"
                multiple
                label="Region"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <InputHotKeyWrapper>
                <v-text-field
                  v-model="editDialog.data.kvkNumber"
                  type="number"
                  label="KvK number"
                />
              </InputHotKeyWrapper>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.ibanNumber"
                label="Iban number"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-text-field
                v-model="editDialog.data.vatNumber"
                :disabled="!editDialog.data.isKOR && !editDialog.data.isZZP"
                label="VAT number"
              />
            </v-flex>
            <v-flex
              xs3
            >
              <v-switch
                v-model="editDialog.data.isKOR"
                label="KOR"
              />
            </v-flex>
            <v-flex
              xs3
            >
              <v-switch
                v-model="editDialog.data.isZZP"
                label="ZZP"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-switch
                v-model="editDialog.data.hasTimeLimitsForFixedPrice"
                label="Time limits for fixed price"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.data.certificates && editDialog.data.certificates.length"
              xs12
            >
              <v-data-table
                :headers="[ { text: 'id', sortable: false }, { text: 'Title', sortable: false }, { text: 'Obtained At', sortable: false } ]"
                :items="certificatesList.filter(item => Boolean(editDialog.data.certificates.find(cert => cert.id === item.id)))"
                hide-actions >
                <template
                  slot="items"
                  slot-scope="props">
                  <td>
                    {{ props.item.id }}
                  </td>
                  <td>
                    {{ props.item.title }}
                  </td>
                  <td>
                    <v-text-field
                      v-model="props.item.obtainedAt"
                      :rules="[v => Boolean(v) || 'Please provide a Obtained at']"
                      type="date"
                      label="Obtained at"
                      class="mb-4"
                    />
                  </td>
                </template>
              </v-data-table>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <input
                id="file"
                ref="myFiles"
                type="file"
                class="custom-file-input"
                multiple
                accept="image/*"
                @change="previewFiles">
              <div class="mt-2">Afbeeldingsgrootte: 124 x 124 px</div>
            </v-flex>
            <v-flex
              xs12
              sm6>
              <div
                v-if="editDialog.data.imageData != null && editDialog.data.imageData.length > 0"
                class="image-preview column-layout justify-center align-center">
                <img
                  :src="editDialog.data.imageData"
                  class="preview">
              </div>
            </v-flex>
            <v-flex
              v-if="editDialog.type === 'edit'"
              xs12>
              <v-btn
                color="primary"
                @click="sendWelcome()">Send welcome e-mail</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import UsersInput from '../../../modules/components/UsersInput.vue'
import AddressInput from '../../../modules/components/AddressInput.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH, BRAND } from '../../../constants/common'

export default {
  components: { CrudTable, UsersInput, AddressInput },
  mixins: [Request, Brands],
  data () {
    return {
      TEXT_FIELD_MAX_LENGTH,
      BRAND,
      typesList: [],
      brandList: [],
      regionList: [],
      certificatesList: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'Avatar', value: 'photo', textValue: 'url', image: true },
        { text: 'User id', value: 'user', textValue: 'id' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        { text: 'Name', custom: (item) =>
        `<div>${item.name} ${item.companyName ? '(' + item.companyName + ')' : ''}</div>`
        },
        { text: 'Tariffs', custom: (item) => 
        `<div style="font-size: 13px">
          ${item.tariffs.driveme ? `<span><b>driveme:</b>&nbsp;${item.tariffs.driveme}</span><br>` : ''}
          ${item.tariffs.edrivers ? `<span><b>edrivers:</b>&nbsp;${item.tariffs.edrivers}</span><br>` : ''}
          ${item.tariffs.rentabob ? `<span><b>rentabob:</b>&nbsp;${item.tariffs.rentabob}</span>` : ''}
        </div>` },
        { text: 'Description', value: 'description' },
        { text: 'Region', value: 'regions', objectKey: 'name' },
        { text: 'Address', value: 'address' },
        { text: 'Min Hours Per Ride', value: 'minHoursPerRide', textValue: 'time' },
        { text: '', value: null }
      ],
      csvData: [],
      loading: true,
      data: [],
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {
          tariffs: {}
        }
      },
      files: {},
      imageData: null,
      driverTypes: {
        driveme: null,
        rentabob: null,
        edrivers: null
      }
    }
  },
  watch: {
    'editDialog.data.brands': {
      handler () {
        if (!this.editDialog.data.brands || !this.editDialog.data.brands.length) {
          this.driverTypes.driveme = null
          this.driverTypes.rentabob = null
          this.driverTypes.edrivers = null
        }
        else if (this.brandList.length) {
          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.DRIVE_ME).find(item => item.id).id)) {
            this.driverTypes.driveme = null
          }

          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.RENTABOB).find(item => item.id).id)) {
            this.driverTypes.rentabob = null
          }

          if (!this.editDialog.data.brands.includes(this.brandList.filter(item => item.name === BRAND.EDRIVERS).find(item => item.id).id)) {
            this.driverTypes.edrivers = null
          }
        }
      },
      deep: true
    }
  },
  computed: {
    driverTypesAll() {
			let result = []
      if (this.driverTypes.driveme) {
        result.push(this.driverTypes.driveme)
      }
      if (this.driverTypes.rentabob) {
        result.push(this.driverTypes.rentabob)
      }
      if (this.driverTypes.edrivers) {
        result.push(this.driverTypes.edrivers)
      }
      return result
		},
  },
  async beforeMount () {
    let getCertificatesList = new Promise((resolve) => {
      this.request('GET', '/driver-certificates?pageSize=9999', {}, ({ data }) => {
        this.certificatesList = data.data.map(item => ({ ...item, obtainedAt: '' }))
        resolve()
      })
    })

    let getRegionList = new Promise((resolve) => {
      this.request('GET', '/regions?pageSize=9999', {}, ({ data }) => {
        this.regionList = data.data
        resolve()
      })
    })

    let getTypesList = new Promise((resolve) => {
      this.request('GET', '/driver-types?pageSize=9999', {}, ({ data: driverTypesData }) => {
        this.typesList = driverTypesData.data
        resolve()
      })
    })

    Promise.all([getCertificatesList, getRegionList, getTypesList]).then(() => {
      
    })

    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    typesListBrand (brandName) {
      return this.typesList.filter(item => item.brand.name === brandName)
    },
    previewFiles (event) {
      this.files = this.$refs.myFiles.files

      var input = event.target

      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.editDialog.data.imageData = e.target.result
          this.editDialog.data.imageName = input.files[0].name
          this.$forceUpdate()
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    async saveDriverPhoto (reference, file) {
      return new Promise((resolve) => {
        let body = {
          data: {
            'name': this.editDialog.data.imageName,
            'content': file,
            'type': 'driver-photo',
            'reference': reference
          }
        }
        this.request('POST', '/files', body, ({ data }) => {
          resolve(1)
        })
      })
    },
    createDriver () {
      this.editDialog.display = true
      this.editDialog.data = { tariffs: {} }

      this.editDialog.type = 'create'

      this.editDialog.saveFunction = () => {
        for(const [key, value] of Object.entries(this.editDialog.data.tariffs)) {
          this.editDialog.data.tariffs[key] = value && value.replace(/,/g, '.')
        }

        let body = {
          data: {
            'user': this.editDialog.data.user,
            'tariffs': this.editDialog.data.tariffs,
            'description': this.editDialog.data.description,
            'address': this.editDialog.data.address,
            'minHoursPerRide': this.editDialog.data.minHoursPerRide,
            'hoursExperience': this.editDialog.data.hoursExperience,
            'yearsExperience': this.editDialog.data.yearsExperience,
            'types': this.driverTypesAll,
            'brands': this.editDialog.data.brands ? this.editDialog.data.brands.map(brand => brand.id || brand) : [],
            'regions': this.editDialog.data.regions ? this.editDialog.data.regions.map(region => region.id || region) : [],
            'adminNote': this.editDialog.data.adminNote,
            'business': this.editDialog.data.business,
            'companyName': this.editDialog.data.companyName,
            'isKOR': this.editDialog.data.isKOR,
            'isZZP': this.editDialog.data.isZZP,
            'kvkNumber': this.editDialog.data.kvkNumber,
            'ibanNumber': this.editDialog.data.ibanNumber,
            'vatNumber': this.editDialog.data.vatNumber,
            'personnelNumber': this.editDialog.data.personnelNumber,
            'hasTimeLimitsForFixedPrice': this.editDialog.data.hasTimeLimitsForFixedPrice ? true : false
          }
        }

        this.request('POST', `/drivers`, body, async ({ data }) => {
          if (this.editDialog.data.certificates && this.editDialog.data.certificates.length) {
            const certificatesPromises = this.editDialog.data.certificates.map((certificate) => {
              const body = {
                data: {
                  driver: data.id,
                  certificate: certificate.id,
                  obtainedAt: certificate.obtainedAt
                }
              }

              return new Promise((resolve) => {
                this.request('POST', `/driver-certificates/drivers`, body, () => {
                  resolve()
                })
              })
            })

            await Promise.all(certificatesPromises)

            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Chauffeur is aangemaakt'
            })
          } else {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'Chauffeur is aangemaakt'
            })
          }
        })
      }
    },
    deleteUser (id) {
      this.request('DELETE', `/drivers/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Chauffeur is verwijderd'
        })
      })
    },
    getData (drivers) {
      this.csvData = drivers.map(driver => ({
        driver: `${driver.name || '-'}`,
        address: driver.address || '-',
        email: driver.user.email || '-',
        phone: driver.user.phone ? driver.user.phone.number : '-',
        description: driver.description || '-',
        id: driver.id
      }))
    },
    restoreDriver (id) {
      this.request('PATCH', `/trash/un-delete/driver/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Driver is restored'
        })
      })
    },
    editDriver (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'

      this.editDialog.data.user = this.editDialog.data.user.id
      this.editDialog.data.minHoursPerRide = this.editDialog.data.minHoursPerRide.time
      this.editDialog.data.yearsExperience = this.editDialog.data.experience.years
      this.editDialog.data.hoursExperience = this.editDialog.data.experience.hours
      this.editDialog.data.imageData = this.editDialog.data.photo != null ? this.editDialog.data.photo.url : null

      this.editDialog.saveFunction = () => {
        for(const [key, value] of Object.entries(this.editDialog.data.tariffs)) {
          this.editDialog.data.tariffs[key] = value && value.replace(/,/g, '.')
        }

        let body = {
          data: {
            'user': this.editDialog.data.user,
            'tariffs': this.editDialog.data.tariffs,
            'description': this.editDialog.data.description,
            'address': this.editDialog.data.address,
            'minHoursPerRide': this.editDialog.data.minHoursPerRide,
            'yearsExperience': this.editDialog.data.yearsExperience,
            'hoursExperience': this.editDialog.data.hoursExperience,
            'brands': this.editDialog.data.brands.map(brand => brand.id || brand),
            'regions': this.editDialog.data.regions.map(region => region.id || region),
            'adminNote': this.editDialog.data.adminNote,
            'isKOR': this.userData.isKOR,
            'business': this.editDialog.data.business,
            'companyName': this.editDialog.data.companyName,
            'isKOR': this.editDialog.data.isKOR,
            'isZZP': this.editDialog.data.isZZP,
            'kvkNumber': this.editDialog.data.kvkNumber,
            'ibanNumber': this.editDialog.data.ibanNumber,
            'vatNumber': this.editDialog.data.vatNumber,
            'personnelNumber': this.editDialog.data.personnelNumber,
            'hasTimeLimitsForFixedPrice': this.editDialog.data.hasTimeLimitsForFixedPrice ? true : false
          }
        }

        this.request('PATCH', `/drivers/${data.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Chauffeur is gewijzigd'
          })

          if (this.editDialog.data.imageData != null) {
            let imageData = this.editDialog.data.imageData.replace(/^data:image.+;base64,/, '')

            this.saveDriverPhoto(data.id, imageData).then(() => {
              this.editDialog.display = false
              this.$refs.crudTable.requestData()
            })
          } else {
            this.editDialog.display = false
            this.$refs.crudTable.requestData()
          }
        })
      }
    },
    sendWelcome () {
      let body = {
        data: {
          driver: this.editDialog.data.id
        }
      }

      this.request('POST', '/emailing/driver-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}
</style>
